<script>
import adminProfileStructure from '@components/adminProfileStructure'
import moment from 'moment'
import {mapState} from "vuex";
import UserCompany from "@components/modals/admin/UserCompany";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {
      title: 'Admin',
  },
  components: {PaginationRow, UserCompany, adminProfileStructure},
  mixins: [PaginatedDataMixin],
  computed:{
      ...mapState({
        user: state => state.auth.currentUser
      }),
  },
  data() {
    return {
      modalUser: null,
      fields: [
        {key: 'user.email', label: 'E-mail πελάτη'},
        {key: 'amount', label: 'Ποσό', formatter: value => { return value ? value.toFixed(2) + '€' : '-'}},
        {key: 'type', label: 'Είδος πληρωμής', formatter: value => {
            if(value === 'RENEW') return '🔄 Ανανέωση';
            if(value === 'PURCHASE') return '✅ Αγορά λογισμικού'
          }},
        {key: 'invoice', label: 'Παραστατικό', formatter: value => {
            if(value === 2) return 'Τιμολόγιο';
            if(value === 1) return 'Απόδειξη';
            return '-'
          }},
        {key: 'updatedAt', label: 'Ημερομηνία πληρωμής', formatter: value => {
            return moment(value).format('DD/MM/YYYY HH:mm')}
        },
        {key: 'actions', label:'Ενέργειες'}
      ],
    }
  },
  created(){
      this.api.url = '/admin/users/transactions';
      this.getData();

  },
  methods:{

      showCompanyDetails(user){
          this.modalUser = user.user;
          this.$refs['show-company-details'].show();
      }

  }
}
</script>
<template>
    <adminProfileStructure :user="user">
        <template v-slot:admin-structure-content>
          <b-card-title>
            Λίστα συναλλαγών (ολοκληρωμένες πληρωμές μέσω Stripe)
          </b-card-title>

            <div v-if="api.rows.length > 0" class="table-responsive table-borderless table-striped mb-0">
                <b-table :items="api.rows" :fields="fields" class="table table-hover" responsive="sm" :current-page="api.page">
                    <template v-slot:cell(img)="row" class="text-center">
                        <i :class="row.item.icon" class="text-center font-17"></i>
                    </template>
                  <template v-slot:cell(name)="row" class="text-center">
                    {{row.item.firstName + ' ' + row.item.lastName}}
                  </template>
                  <template v-slot:cell(isPremium)="row" class="text-center">
                    <b-badge v-if="row.item.isPremium" variant="success">✓</b-badge>
                    <b-badge v-else variant="danger">✗</b-badge>
                  </template>
                    <template v-slot:cell(actions)="row">
                      <b-button variant="primary" size="xs" @click="showCompanyDetails(row.item)">Στοιχεία Τιμολόγησης</b-button>
                    </template>
                </b-table>
            </div>

          <pagination-row :api="api" @fetchData="getData"></pagination-row>

            <b-modal id="show-company-details" ref="show-company-details" title="Στοιχεία τιμολόγησης" hide-footer>
              <UserCompany :user="modalUser" v-if="modalUser && modalUser.userCompany"></UserCompany>
            </b-modal>

        </template>
    </adminProfileStructure>
    <!-- end row-->
</template>
